<template>
  <div class="row">
    <div class="col-12 col-sm-6">
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          :value="value"
          @input="$emit('input', $event.target.value)">
        <div class="input-group-append">
          <span class="input-group-text text-light">ปี ค.ศ.</span>
          <span
            v-if="!topic.approvedAt"
            class="input-group-text text-success pointer"
            @click="createTopic">
            เพิ่มรายการ
          </span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-row">
        <DetailFormUser
          class="col-6"
          title="จัดทำ/ทบทวน"
          :value="getUser('reviewed')"
          :isSubmit="!topic.approvedAt && !topic.reviewedAt && $auth.hasRole(`paper:${paperType}:topic:review`)"
          :isUndo="!topic.approvedAt && topic.reviewedAt && $auth.hasRole(`paper:${paperType}:topic:review`)"
          :submitFunc="reviewSubmit"
          :undoFunc="reviewUndo"
          @updated="emitUpdate">
        </DetailFormUser>

        <DetailFormUser
          class="col-6"
          title="อนุมัติ"
          :value="getUser('approved')"
          :isSubmit="topic.reviewedAt && !topic.approvedAt && $auth.hasRole(`paper:${paperType}:topic:approve`)"
          :isUndo="topic.reviewedAt && topic.approvedAt && $auth.hasRole(`paper:${paperType}:topic:approve`)"
          :submitFunc="approveSubmit"
          :undoFunc="approveUndo"
          @updated="emitUpdate">
        </DetailFormUser>
      </div>
    </div>
  </div>
</template>

<script>
import DetailFormUser from './DetailFormUser'
import {
  REVIEW_SUBMIT_PAPER_TOPIC,
  REVIEW_UNDO_PAPER_TOPIC,
  APPROVE_SUBMIT_PAPER_TOPIC,
  APPROVE_UNDO_PAPER_TOPIC,
  CREATE_PAPER_TOPIC
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    topic: {
      type: Object,
      required: false
    }
  },
  data () {
    return {

    }
  },
  methods: {
    emitUpdate () {
      this.$emit('updated', null)
    },
    createTopic () {
      this.$apollo.mutate({
        mutation: CREATE_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          input: {
            name: '',
            responsible: '',
            cron: '',
            budget: 0,
            remark: '',
            yearAt: +this.value
          }
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    getUser (type) {
      const date = this.topic[type + 'At']
      const user = this.topic[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    reviewSubmit (date) {
      return this.$apollo.mutate({
        mutation: REVIEW_SUBMIT_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          yearAt: +this.value,
          paperId: this.paperId,
          topicId: this.topic.id,
          submitDate: date
        }
      })
    },
    reviewUndo () {
      return this.$apollo.mutate({
        mutation: REVIEW_UNDO_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          yearAt: +this.value,
          paperId: this.paperId,
          topicId: this.topic.id,
        }
      })
    },
    approveSubmit (date) {
      return this.$apollo.mutate({
        mutation: APPROVE_SUBMIT_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          yearAt: +this.value,
          paperId: this.paperId,
          topicId: this.topic.id,
          submitDate: date
        }
      })
    },
    approveUndo () {
      return this.$apollo.mutate({
        mutation: APPROVE_UNDO_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          yearAt: +this.value,
          paperId: this.paperId,
          topicId: this.topic.id,
        }
      })
    },
  },
  components: {
    DetailFormUser
  }
}
</script>

<style lang="css" scoped>
</style>
