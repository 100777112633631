<template>
  <div>
    <div class="card">
      <h6 class="card-header" v-if="!toggle">
        {{index + 1}}.
        <span v-if="!isApproved">
          <fa
            v-if="!topic.approvedAt"
            icon="pencil-alt"
            class="text-warning mx-2 pointer"
            @click="toggle = !toggle">
          </fa>
          <fa
            icon="lock"
            class="mx-2 pointer"
            :class="{
              'text-danger': topic.approvedAt,
              'text-secondary': !topic.approvedAt,
            }"
            @click="toggleLock">
          </fa>
          <fa
            icon="arrow-down"
            class="text-primary pointer mx-2 float-right"
            @click="moveTopic(topic.id, 1)">
          </fa>
          <fa
            icon="arrow-up"
            class="text-primary pointer mx-2 float-right"
            @click="moveTopic(topic.id, -1)">
          </fa>
        </span>
      </h6>
      <div class="card-body pb-0" v-if="!toggle">
        <div class="card-text">
          <dl class="row">
            <dt class="col-4 col-sm-3 font-weight-normal text-right">กิจกรรม:</dt>
            <dd class="col-8 col-sm-9 pre-line">{{topic.name || '-'}}</dd>

            <dt class="col-4 col-sm-3 text-warning font-weight-normal text-right">ผู้รับผิดชอบ:</dt>
            <dd class="col-8 col-sm-9 pre-line">{{topic.responsible || '-'}}</dd>

            <dt class="col-4 col-sm-3 text-info font-weight-normal text-right">เวลา (cron):</dt>
            <dd class="col-8 col-sm-9">
              {{topic.cron || '-'}}
              <div
                v-for="ac in topic.docConfigActionPlans"
                :key="ac.id"
                class="text-info">
                <fa icon="sync"></fa>
                {{ac.docType}}: {{ac.type}}
              </div>
            </dd>

            <dt
              v-if="topic.budget"
              class="col-4 col-sm-3 text-success font-weight-normal text-right">
              งบประมาณ:
            </dt>
            <dd v-if="topic.budget" class="col-8 col-sm-9">
              {{topic.budget || '-'}}
            </dd>

            <dt
              v-if="topic.remark"
              class="col-4 col-sm-3 text-primary font-weight-normal text-right">
              หมายเหตุ:
            </dt>
            <dd
              v-if="topic.remark"
              class="col-8 col-sm-9 pre-line">
              {{topic.remark || '-'}}
            </dd>
          </dl>
        </div>
      </div>

      <div class="card-header" v-if="toggle">
        <h6 class="card-title">
          {{index + 1}}.
          <fa
            icon="pencil-alt"
            class="text-warning ml-1 pointer"
            @click="toggle = !toggle">
          </fa>
        </h6>

        <div class="form-row">
          <sgv-input-textarea
            label="กิจกรรม"
            v-model="formData.name"
            class="col-12"
            :rows="1"
            auto-grow
            :validations="[
              {text: 'required!', value: !$v.formData.name.required && $v.formData.$dirty}
            ]">
          </sgv-input-textarea>

          <sgv-input-text
            label="ผู้รับผิดชอบ"
            v-model="formData.responsible"
            class="col-12"
            :validations="[
              {text: 'required!', value: !$v.formData.responsible.required && $v.formData.$dirty}
            ]">
          </sgv-input-text>

          <sgv-input-currency
            label="งบประมาณ (บาท)"
            v-model="formData.budget"
            class="col-6">
          </sgv-input-currency>

          <sgv-input-text
            label="เวลา (cron)"
            v-model="formData.cron"
            class="col-6"
            :disabled="topic.docConfigActionPlans.length > 0"
            :validations="[
              {text: 'required!', value: !$v.formData.cron.required && $v.formData.$dirty}
            ]">
          </sgv-input-text>

          <sgv-input-textarea
            label="หมายเหตุ"
            auto-grow
            v-model="formData.remark"
            class="col-12">
          </sgv-input-textarea>
        </div>

        <button
          type="button"
          class="btn btn-warning"
          @click="updateTopic(topic.id)">
          แก้ไข
        </button>

        <button
          type="button"
          class="btn btn-danger float-right"
          @click="destroyTopic(topic.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  UPDATE_PAPER_TOPIC,
  DESTROY_PAPER_TOPIC,
  LOCK_PAPER_TOPIC,
  UNLOCK_PAPER_TOPIC,
  MOVE_PAPER_TOPIC
} from './graph'

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    topic: {
      type: Object,
      required: true
    },
    isApproved: {
      type: Boolean,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        name: '',
        responsible: '',
        cron: '',
        budget: '',
        remark: ''
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      responsible: {required},
      cron: {required}
    }
  },
  methods: {
    updateTopic (id) {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id,
          input: this.formData
        }
      })
      .then(() => {
        this.$v.formData.$reset()
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyTopic (id) {
      this.$apollo.mutate({
        mutation: DESTROY_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    moveTopic (id, step) {
      this.$apollo.mutate({
        mutation: MOVE_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id,
          step
        }
      })
      .then(() => {
        this.$toasted.global.success("แก้ไขสำเร็จ")
        this.emitUpdated()
      })
      .catch(this.$toasted.global.error)
    },
    toggleLock () {
      if (this.topic.approvedAt) this.unlockTopic(this.topic.id)
      else this.lockTopic(this.topic.id)
    },
    lockTopic (id) {
      this.$apollo.mutate({
        mutation: LOCK_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("อนุมัติสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    unlockTopic (id) {
      this.$apollo.mutate({
        mutation: UNLOCK_PAPER_TOPIC(this.templateType),
        variables: {
          paperType: this.paperType,
          topicId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ย้อนรายการสำเร็จ")
        this.emitUpdated()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    topic: {
      handler (v) {
        this.formData.name = v.name
        this.formData.responsible = v.responsible
        this.formData.cron = v.cron
        this.formData.budget = v.budget
        this.formData.remark = v.remark
      },
      immediate: true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
