import gql from 'graphql-tag'

const detailResponse = `
  id type order name
  responsible budget cron remark
  reviewedAt reviewedUser {id name}
  approvedAt approvedUser {id name}
  docConfigActionPlans {
    id type docType name
  }
`

export const LIST_TOPIC = (templateType) => gql`query LIST_TOPIC ($paperType: String!, $paperId: Int!, $year: Int!) {
  topics: list${templateType}Topic (paperType: $paperType, paperId: $paperId, year: $year) {${detailResponse}}
}`

export const CREATE_PAPER_TOPIC = (templateType) => gql`mutation CREATE_PAPER_TOPIC ($paperType: String!, $paperId: Int!, $input: ${templateType}TopicInput!) {
  createTopic: create${templateType}Topic (paperType: $paperType, paperId: $paperId, input: $input) {${detailResponse}}
}`

export const UPDATE_PAPER_TOPIC = (templateType) => gql`mutation UPDATE_PAPER_TOPIC ($paperType: String!, $topicId: Int!, $input: ${templateType}TopicInput!) {
  updateTopic: update${templateType}Topic (paperType: $paperType, topicId: $topicId, input: $input) {${detailResponse}}
}`

export const DESTROY_PAPER_TOPIC = (templateType) => gql`mutation DESTROY_PAPER_TOPIC ($paperType: String!, $topicId: Int!) {
  destroyTopic: destroy${templateType}Topic (paperType: $paperType, topicId: $topicId) {id}
}`

export const MOVE_PAPER_TOPIC = (templateType) => gql`mutation MOVE_PAPER_TOPIC ($paperType: String!, $topicId: Int!, $step: Int!) {
  moveTopic: move${templateType}Topic (paperType: $paperType, topicId: $topicId, step: $step) {id}
}`

export const LOCK_PAPER_TOPIC = (templateType) => gql`mutation LOCK_PAPER_TOPIC ($paperType: String!, $topicId: Int!) {
  lockTopic: lock${templateType}Topic (paperType: $paperType, topicId: $topicId) {${detailResponse}}
}`

export const UNLOCK_PAPER_TOPIC = (templateType) => gql`mutation UNLOCK_PAPER_TOPIC ($paperType: String!, $topicId: Int!) {
  unlockTopic: unlock${templateType}Topic (paperType: $paperType, topicId: $topicId) {${detailResponse}}
}`

export const REVIEW_SUBMIT_PAPER_TOPIC = (templateType) => gql`mutation REVIEW_SUBMIT_PAPER_TOPIC ($paperType: String!, $yearAt: Int!, $paperId: Int!, $topicId: Int!, $submitDate: Date!) {
  topic: reviewSubmit${templateType}Topic (paperType: $paperType, yearAt: $yearAt, paperId: $paperId, topicId: $topicId, submitDate: $submitDate) {${detailResponse}}
}`

export const REVIEW_UNDO_PAPER_TOPIC = (templateType) => gql`mutation REVIEW_UNDO_PAPER_TOPIC ($paperType: String!, $yearAt: Int!, $paperId: Int!, $topicId: Int!) {
  topic: reviewUndo${templateType}Topic (paperType: $paperType, yearAt: $yearAt, paperId: $paperId, topicId: $topicId) {${detailResponse}}
}`

export const APPROVE_SUBMIT_PAPER_TOPIC = (templateType) => gql`mutation APPROVE_SUBMIT_PAPER_TOPIC ($paperType: String!, $yearAt: Int!, $paperId: Int!, $topicId: Int!, $submitDate: Date!) {
  topic: approveSubmit${templateType}Topic (paperType: $paperType, yearAt: $yearAt, paperId: $paperId, topicId: $topicId, submitDate: $submitDate) {${detailResponse}}
}`

export const APPROVE_UNDO_PAPER_TOPIC = (templateType) => gql`mutation APPROVE_UNDO_PAPER_TOPIC ($paperType: String!, $yearAt: Int!, $paperId: Int!, $topicId: Int!) {
  topic: approveUndo${templateType}Topic (paperType: $paperType, yearAt: $yearAt, paperId: $paperId, topicId: $topicId) {${detailResponse}}
}`
